<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header :add_class="['header-auto-show']" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <!-- Page Content -->
    <div data-card-height="cover-full" class="page-content pb-0">
      <div class="card mb-5">
        <div class="content px-4">
          <div class="text-center mt-4">
            <p class="font-600 color-highlight mb-1 font-16">
              {{ pageTitle }}
            </p>
            <h1 class="font-40">Daftar</h1>
            <p class="boxed-text-xl color-white opacity-50 pt-3 font-15"></p>
          </div>
          <!-- Form Input -->

          <p v-if="errors.errorMsg" class="color-red-dark mb-3">
            {{ errors.errorMsg }}
          </p>
          <!-- User as Employer -->
          <div v-if="userType === 'E'">
            <p class="font-18 font-500 mb-3  color-highlight">
              Data Profil Bisnis
            </p>
            <div
              class="input-style no-borders has-icon validate-field mb-4"
              :class="{ 'input-style-active': business_name }"
            >
              <i class="fas fa-building"></i>
              <input
                type="text"
                class="form-control validate-name"
                id="form1"
                v-model="business_name"
                placeholder="Nama Bisnis"
                required
              />
              <label for="form1">Nama Bisnis</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                :class="{
                  'color-red-dark font-800 req-val': errors.business_name,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style no-borders has-icon mb-4"
              :class="{ 'input-style-active': contact_name }"
            >
              <i class="fas fa-map"></i>
              <input
                id="form1b"
                v-model="contact_name"




                required
                maxlength="500"
                placeholder="Nama Kontak"
              />
              <label for="form1b">Kontak</label>
              <em
                :class="{
                  'color-red-dark font-800 req-val': errors.contact_name,
                }"
                >(required)</em
              >
            </div>
          </div>
          <!-- End User as Employer -->

          <!-- user as job seeker -->
          <div class="mt-1" v-if="userType === 'J'">
            <p class="font-18 font-500 mb-3 color-highlight">Data Profil</p>
            <div
              class="
                input-style
                no-borders
                has-icon
                validate-field
                mb-4
              "
              :class="{ 'input-style-active': first_name }"
            >
              <i class="fas fa-user"></i>
              <input
                type="text"
                class="form-control validate-name"
                id="form1"
                v-model="first_name"
                placeholder="Nama Depan"
                required
              />
              <label for="form1">Nama Depan</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                :class="{
                  'color-red-dark font-800 req-val': errors.first_name,
                }"
                >(required)</em
              >
            </div>

            <div
              class="
                input-style
                no-borders
                has-icon
                validate-field
                mb-4
              "
              :class="{ 'input-style-active': last_name }"
            >
              <i class="fas fa-user"></i>
              <input
                type="text"
                class="form-control validate-name"
                id="form1"
                v-model="last_name"
                placeholder="Nama Belakang"
                required
              />
              <label for="form1">Nama Belakang</label>
              <i class="fa fa-times disabled invalid color-red-dark"></i>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <em
                :class="{
                  'color-red-dark font-800 req-val': errors.last_name,
                }"
                >(required)</em
              >
            </div>
        </div>
          <!--End User as job seeker -->


          <!--Domisili -->
          <p v-if="userType === 'E'"  class="font-18 font-500 mb-3 mt-5 color-highlight">Domisili</p>
<!--          <div-->
<!--            class="-->
<!--              input-style-->
<!--              no-borders no-icon-->
<!--              validate-field-->
<!--              mb-4-->
<!--              input-style-always-active-->
<!--            "-->
<!--            :class="{ 'input-style-active': country }"-->
<!--          >-->
<!--            <select class="custom-form-control" id="form1k" v-model="country">-->
<!--              <option value="default" disabled="" selected="">Negara</option>-->
<!--              <option-->
<!--                v-for="country in master_data.country"-->
<!--                :key="country.id"-->
<!--                :value="country.id"-->
<!--              >-->
<!--                {{ country.name }}-->
<!--              </option>-->
<!--            </select>-->
<!--            <label for="form1k">Negara</label>-->
<!--            <span><i class="fa fa-chevron-down"></i></span>-->
<!--            <i class="fa fa-check disabled valid color-green-dark"></i>-->
<!--            <i class="fa fa-check disabled invalid color-red-dark"></i>-->
<!--            <em-->
<!--              class="me-3"-->
<!--              :class="{ 'color-red-dark font-800 req-val': errors.country }"-->
<!--              >(required)</em-->
<!--            >-->
<!--          </div>-->

          <div v-if="userType === 'E'"
            class="
              input-style
              no-borders no-icon
              validate-field
              mb-4
              input-style-always-active
            "
            :class="{ 'input-style-active': province }"
          >
            <select class="custom-form-control" id="form1k" v-model="province">
              <option value="default" disabled="" selected="">Provinsi</option>
              <option
                v-for="province in master_data.province"
                :key="province.id"
                :value="province.id"
              >
                {{ province.name }}
              </option>
            </select>
            <label for="form1k">Provinsi</label>
            <span><i class="fa fa-chevron-down"></i></span>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <i class="fa fa-check disabled invalid color-red-dark"></i>
            <em
              class="me-3"
              :class="{ 'color-red-dark font-800 req-val': errors.province }"
              >(required)</em
            >
          </div>

          <div
v-if="userType === 'E'"
            class="
              input-style
              no-borders no-icon
              validate-field
              mb-4
              input-style-always-active
            "
            :class="{ 'input-style-active': city }"
          >
            <select class="custom-form-control" id="form1k" v-model="city">
              <option value="default" disabled selected>Pilih Domisili</option>
              <option v-for="city in cities" :key="city.id" :value="city.id">
                {{ city.name }}
              </option>
            </select>
            <label for="form1k">Kota Domisili</label>
            <span><i class="fa fa-chevron-down"></i></span>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <i class="fa fa-check disabled invalid color-red-dark"></i>
            <em
              class="me-3"
              :class="{ 'color-red-dark font-800 req-val': errors.city }"
              >(required)</em
            >




          </div>
          <!-- End Domisili -->

          <p v-if="userType === 'E'" class="font-18 font-500 mb-3 mt-5 color-highlight">Data Akun</p>
          <div
            class="input-style no-borders has-icon validate-field"
            :class="{ 'input-style-active': email }"
          >
            <i class="fa fa-at"></i>
            <input
              type="email"
              class="form-control validate-email"
              id="form1ac"
              placeholder="Alamat email login"
              v-model="email"
              required
            />
            <label for="form1ac">Alamat Email</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800 req-val': errors.email }"
              >(required)</em
            >
          </div>
          <div
            class="input-style no-borders has-icon validate-field"
            :class="{ 'input-style-active': mobile_no }"
          >
            <i class="fas fa-mobile-alt"></i>
            <input
              type="tel"
              class="form-control"
              id="formmn"
              placeholder="Nomor Hp"
              v-model="mobile_no"
              min="8"
              required
            />
            <label for="formmn">Nomor Hp</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800 req-val': errors.mobile_no }"
              >(required)</em
            >
          </div>


          <div
            class="input-style no-borders has-icon validate-field"
            :class="{ 'input-style-active': password }"
          >
            <i class="fa fa-lock"></i>
            <input
              type="password"
              class="form-control validate-password"
              id="form1ad"
              placeholder="Kata Sandi"
              v-model="password"
              required
            />
            <label for="form1ad">Kata Sandi</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800 req-val': errors.password }"
              >(required)</em
            >
          </div>

          <div
            class="input-style no-borders has-icon validate-field"
            :class="{ 'input-style-active': password2 }"
          >
            <i class="fa fa-lock"></i>
            <input
              type="password"
              class="form-control validate-password"
              id="form1ae"
              placeholder="Konfirmasi Kata Sandi"
              v-model="password2"
              required
            />
            <label for="form1ae">Konfirmasi Kata Sandi</label>
            <i class="fa fa-times disabled invalid color-red-dark"></i>
            <i class="fa fa-check disabled valid color-green-dark"></i>
            <em :class="{ 'color-red-dark font-800 req-val': errors.password2 }"
              >(required)</em
            >
          </div>
          <div class="form-check icon-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="check4"
              v-model="term"
            />
            <label class="form-check-label" for="check4">
              dengan mendaftar anda menyetujui
              <router-link :to="{ name: 'PrivacyPolicy' }" target="_blank"
                >Kebijakan</router-link
              >
              dan
              <router-link :to="{ name: 'Term' }" target="_blank"
                >Syarat</router-link
              >
              dari Pasjob
            </label>
            <i class="icon-check-1 far fa-square color-gray-dark font-16"></i>
            <i
              class="icon-check-2 far fa-check-square font-16 color-highlight"
            ></i>
          </div>

          <a
            @click.prevent="submitForm"
            href="#"
            class="
              btn btn-full btn-l
              font-600 font-13
              gradient-highlight
              mt-4
              rounded-s
            "
          >
            Buat Akun
          </a>
          <!-- End  Form Input -->

          <div class="row pt-3 mb-3">
            <div class="col-6 text-start font-11">
              <router-link
                :to="{ name: 'ResetPassword' }"
                class="color-highlight"
                >Lupa Password ?</router-link
              >
            </div>
            <div class="col-6 text-end font-11">
              <router-link :to="{ name: 'SignIn' }" class="color-highlight"
                >login</router-link
              >
            </div>
          </div>
          <div class="row">
            <a
              @click="googleSignIn"
              href="#"
              class="
                mb-3
                text-start
                btn btn-m btn-full
                bg-google
                btn-icon
                font-600
              "
              ><i class="fab fa-google font-16 text-center"></i>Daftar dengan Google</a
            >
          </div>
      </div>
    </div>
    <!-- Sign Up section -->
    <!-- Page content ends here-->
  </div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";
/*import "../assets/code/scripts/custom.js";*/

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "SignUp",
  components: {
    Header,
    Footer,
  },
  inject: ["isAuthenticated"],
  data() {
    return {
      email: "",
      mobile_no: "",
      password: "",
      password2: "",
      userType: "J",

      country: "1",
      province: "",
      city: "",

      term: "",
      termOfCondition: "",

      // userprofile
      first_name: "",
      last_name: "",
      last_degree: "",
      year_graduate: "",
      // userprofile

      // company profile
      business_name: "",
      contact_name: "",
      // End company profile

      cities: "",
      dateInput: false,
      pageTitle: "Pencari Kerja",
      showCompanyProfile: false,
      showUserProfile: false,
      errors: {},
      isLoading: true,
      master_data: {},
    };
  },
  watch: {
    province() {
      this.cities = this.master_data.province.filter((item) => {
        return item.id === this.province;
      });
      this.cities = this.cities[0].city;
    },
  },
  mounted() {
    init_template();
    this.getMasterData();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
    if (this.$route.query.user_type === "employer") {
      this.pageTitle = "Bisnis";
      this.userType = "E";
    }
  },
  methods: {
    menuClose,
    menuOpen,
    gotoTerm(url) {
      let route = this.$router.resolve({ name: url });
      window.open(route.href);
    },
    async googleSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        this.user = googleUser.getBasicProfile().getEmail();
        if (googleUser.getAuthResponse) {
          const formData = {
            auth_token: googleUser.getAuthResponse().id_token,
            user_type: this.$route.query.user_type,
          };
          console.log(formData);
          await axios
            .post("/api/v1/social-auth/google/", formData)
            .then((response) => {
              const user_type = response.data.user_type;
              const token = response.data.tokens.access;
              const refresh = response.data.tokens.refresh;

              const accountData = {
                userType: user_type,
                isVerified: response.data.is_verified,
                email: response.data.email,
              };

              axios.defaults.headers.common["Authorization"] =




                "Bearer " + token;

              this.$store.commit("addAccount", accountData);
              localStorage.setItem("token", token);
              localStorage.setItem("refresh", refresh);

              this.$store.commit("setToken", token);
              this.$store.commit("setRefresh", refresh);
              this.$store.commit("setUserType", user_type);


              const toPath = this.$route.query.to || "/dashboard";
              this.$router.push(toPath);
            })
            .catch((error) => {
              console.log("error : ", error);
            });
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    validateInput() {
      this.errors = {};
      var dataInput = {
        mobile_no: this.mobile_no,
        email: this.email,
        password: this.password,
        password2: this.password,
        user_type: this.userType,
        //company Profile
      };
      console.log('dateinput :', dataInput)

      // PrivacyPolicy

      if (!this.term) {
        this.errors["errorMsg"] =
          "Pastikan anda menyetujui kebijakan privasi dan syarat ketentuan kami";
      }

      // validate email input
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(this.email)) {
        this.errors["email"] = "masukkan email yang valid";
        this.errors["errorMsg"] = "masukkan email yang valid";
      }

      // password must match
      if (this.password !== this.password2) {
        this.errors["password"] = "kata sandi tidak sama";
        this.errors["password2"] = "kata sandi tidak sama";
        this.errors["errorMsg"] = "kata sandi tidak sama";
      }

      // phone number validate
      if (this.mobile_no.length < 8) {
        this.errors["mobile_no"] = "nomor hp tidak boleh kurang dari 8 digit";
        this.errors["errorMsg"] = "nomor hp tidak boleh kurang dari 8 digit";
      }
      if (this.mobile_no.length > 13) {
        this.errors["mobile_no"] = "nomor hp maksimal 13 digit";
        this.errors["errorMsg"] = "nomor hp maksimal 13 digit";
      }

      var phoneNumeber = this.mobile_no.slice(0, 2);
      if (phoneNumeber !== "08") {
        this.errors["mobile_no"] = "format nomor hp salah";
        this.errors["errorMsg"] = "format nomor hp salah";
      }
      if (this.userType === "E") {
        dataInput = {
          ...dataInput,
          business_name: this.business_name,
          contact_name: this.contact_name,
          country: this.country,
          province: this.province,
          city: this.city,
        };

      }

      if (this.userType === "J") {
        dataInput = {
          ...dataInput,
          first_name: this.first_name,
          last_name: this.last_name,
        };

        // validate year graduate




      }

      // password not lest 8 characters
      if (this.password.length < 8) {
        this.errors["password"] = "kata sandi kurang dari 8 characters";
        this.errors["password2"] = "kata sandi kurang dari 8 characters";
        this.errors["errorMsg"] =
          "kata sandi tidak boleh kurang dari 8 karakter";
      }

      // input not empty check
      for (const key in dataInput) {
        if (dataInput[key] === "") {
          this.errors[key] = "Empty Input";
          this.errors["errorMsg"] = "Pastikan sudah mengisi semua form";
        }
      }
      if (Object.keys(this.errors).length) {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    /*get input data */
    getData() {
      return {
        mobile_no: this.mobile_no,
        email: this.email,
        password: this.password,
        user_type: this.userType,
      };
    },
    async getMasterData() {
      await axios
        .get("/api/v1/employer/master/")
        .then((response) => {
          this.master_data = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },

    async createProfile() {
      const data1 = {
      };

      var formData = {};

      var apiUrl = "/api/v1/job-seeker/profile/";

      if (this.userType === "J") {
        formData = {
          ...data1,
          first_name: this.first_name,
          last_name: this.last_name,
        };
      } else if (this.userType === "E") {
        apiUrl = "/api/v1/employer/company/profile/";
        formData = {
          ...data1,
        country: this.country,
        province: this.province,
        city: this.city,
          business_name: this.business_name,
          contact_name: this.contact_name,
        };
      }

      await axios
        .post(apiUrl, formData)
        .then((response) => {
          var id = response.data.id;
          localStorage.setItem("userProfileId", id);
          this.$store.commit("setId", id);
          console.log(id);

          this.$router.push("/success");
          /*user is go another form page*/
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors[
                "error"
              ] = `${property}: ${error.response.data[property]}`;
            }
            console.log(JSON.stringify(error.response.data));
          } else if (error.message) {
            this.errors["error"] = "Something went wrong. please try again";
            console.log(JSON.stringify(error));
          }
        });
    },
    async createAccount() {
      const formData = this.getData();
      if(this.$route.query.invite_code){
        formData['invite_code'] = this.$route.query.invite_code
      }

      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem["token"];

      /*create account*/
      await axios
        .post("/api/v1/auth/register/", formData)
        .then((response) => {
          const user_type = response.data.user_type;
          const token = response.data.tokens.access;
          const refresh = response.data.tokens.refresh;

          const accountData = {
            userType: user_type,
            isVerified: response.data.is_verified,
            email: response.data.email,
          };

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          this.$store.commit("addAccount", accountData);
          localStorage.setItem("token", token);
          localStorage.setItem("refresh", refresh);

          this.$store.commit("setToken", token);
          this.$store.commit("setRefresh", refresh);

          this.createProfile();
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors[
                "error"
              ] = `${property}: ${error.response.data[property]}`;
              if (property === "email") {
                this.errors["errorMsg"] = "user dengan email ini telah ada";
              }
            }
            console.log(JSON.stringify(error.response.data));
            window.scroll({
              top: 0,
              behavior: "smooth",
            });
          } else if (error.message) {
            this.errors["error"] = "Something went wrong. please try again";
            console.log(JSON.stringify(error));
          }
        });
    },
    async submitForm() {
      this.validateInput();
      if (!Object.keys(this.errors).length) {
        this.createAccount();
      }
    },
  },
};
</script>

<style>
.custom-form-control {
  background-color: #fff;
  color: #212529;
  border: 1px solid #ced4da;
}
.req-val {
  opacity: 1 !important;
}
</style>
